import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from './Routes/Home/Home';
import Signup from './Routes/SignUp/SignUp';
import Login from './Routes/Login/Login';
import './App.css';
import SelectedCategory from './Routes/SelectedCategory/SelectedCategory';
import ProductDetails from './Routes/ProductDetails/ProductDetails';
import Wishlist from './Routes/Wishlist/Wishlist';
import Cart from './Routes/Cart/Cart';
import User from './Routes/User/User';
import SearchProduct from './Routes/SearchProduct/SearchProduct';
import Seller from './Routes/Seller/Seller';
import SellerProfile from './Routes/Seller/SellerProfile/SellerProfile';

// Import Stripe Elements for Payment
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './Routes/Checkout/CheckoutForm';
import AdminPanel from './Routes/AdminPanel/AdminPanel';

// Hardcoded publishable key
const stripePromise = loadStripe('pk_test_51Q4dkRB4BHH2FyeZoCWU8s7GPNZH92w4zB01vS75mhsXCCNwTK14cPaZEpPsZSxBtNCAHfpyXYn0Zkx5ArpHfpiy00mzYcjdeZ');

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Route path='/' exact component={Home} />
        <Route path='/userSignup' exact component={Signup} />
        <Route path='/userLogin' exact component={Login} />

        <Route
          path='/category/:id' exact
          render={props => <SelectedCategory key={props.location.pathname} {...props} />}
        />
        <Route
          path='/category/:id/:id2' exact
          render={props => <SelectedCategory key={props.location.pathname} {...props} />}
        />
        <Route
          path='/product/id/:id' exact
          render={props => <ProductDetails key={props.location.pathname} {...props} />}
        />

        <Route path='/wishlist' exact component={Wishlist} />
        <Route path='/cart' exact component={Cart} />
        <Route path='/user' exact component={User} />

        <Route
          path='/search/:searchTerm' exact
          render={props => <SearchProduct key={props.location.pathname} {...props} />}
        />
        <Route
          path='/search/:searchTerm/:gender' exact
          render={props => <SearchProduct key={props.location.pathname} {...props} />}
        />

        <Route path='/seller' exact component={Seller} />
        <Route path='/seller/user' exact component={SellerProfile} />

        

        {/* New Route for CheckoutForm */}
        <Route path='/checkout' exact
          render={() => (
            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          )}
        />
      </BrowserRouter>
    </div>
  );
}

export default App;
