import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'; // Import withRouter for routing
import Navbar from '../../components/Navbar/Navbar';
import './SignUp.css';
import Spinner from '../../components/Spinner/Spinner';
import Confirmation from './Confirmation/Confirmation';
import Error from '../Login/Error/Error';
import Form from './Form';
import ServerService from '../../API/ServerService';

class SignUp extends Component {

  state = {
    loading: false,
    statusOk: null
  }

  errorReload = () => {
    this.setState({ loading: false, statusOk: null });
  }

  onSubmit = (details) => {

    const userData = {
      firstName: details.firstName,
      lastName: details.lastName,
      username: details.email,
      password: details.password,
      confirmPassword: details.password,
      gender: details.gender,
      roles: details.user,
    }

    const sendData = (userData) => {
      ServerService.register(userData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.setState({ loading: false, statusOk: true });
            // Use history.push for redirection
            this.props.history.push('/userLogin');  // Redirect to login page after successful registration
          }
        })
        .catch(error => {
          this.setState({ statusOk: false });
        })
    }
    sendData(userData);

    this.setState({ loading: true });
  }

  render() {
    if (this.state.statusOk === false) {
      return (
        <div>
          <Navbar />
          <div className='backdrop'>
            <div className='signup_box error_box'>
              <Error reload={this.errorReload} showExtraText={false} content='Something went wrong!' />
            </div>
          </div>
        </div>
      )
    }

    if (this.state.loading) {
      return (
        <div>
          <Navbar shadow={false} />
          <div className='backdrop'>
            <div className='signup_box'>
              <Spinner />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <Form submitHandler={this.onSubmit} />
      </div>
    );
  }
}

// Wrap the component with `withRouter` to access history for redirection
export default withRouter(SignUp);
