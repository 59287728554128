import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './CheckoutForm.css';  // Import CSS for styling

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const { cartValue, itemName } = location.state; // Get cartValue and itemName from state

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [name, setName] = useState(''); // Name of card owner
  const [cardNumber, setCardNumber] = useState(''); // Card number input

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!name) {
      setErrorMessage("Please enter the name on the card.");
      setLoading(false);
      return;
    }

    // Validate the card number (additional validation can be added here)
    if (!/^\d{16}$/.test(cardNumber.replace(/\s/g, ''))) {
      setErrorMessage("Please enter a valid card number.");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      // Create Payment Intent on the server
      const response = await axios.post('/api/payment/create-payment-intent', {
        amount: cartValue * 100,  // cartValue in cents
        currency: 'usd',
      });

      const { clientSecret } = response.data;

      // Confirm the card payment with Stripe
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name, // Include name in billing details
          },
        },
      });

      if (error) {
        setErrorMessage(error.message);
      } else if (paymentIntent.status === 'succeeded') {
        setSuccessMessage('Payment successful!');
      }

    } catch (error) {
      setErrorMessage('Payment failed. Please try again.');
    }

    setLoading(false);
  };

  return (
    <div className="checkout-container">
      <h2>Payment</h2>
      <form onSubmit={handleSubmit} className="checkout-form">
        <div className="input-group">
          <label htmlFor="name">Name on Card</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name on Card"
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="card-number">Card Number</label>
          <input
            type="text"
            id="card-number"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            placeholder="0000 0000 0000 0000"
            required
            maxLength="19" // Allow spaces (4 groups of 4 digits)
          />
        </div>
        <div className="input-group">
          <label>Card Details</label>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </div>
        <div className="input-group">
          <div className="expiry-cvc">
            <div className='expiry-date'>
              <label>Expiration Date (MM / YY)</label>
              <input
                type="text"
                placeholder="MM"
                required
                maxLength="2"
              />
              <input
                type="text"
                placeholder="YY"
                required
                maxLength="2"
              />
            </div>
            <div>
              <label>CVV</label>
              <input
                type="text"
                placeholder="CVC"
                required
                maxLength="4"
              />
            </div>
          </div>
        </div>
        <div className="billing-summary">
          <h3>ITEM 1</h3>
          <p>{itemName} - ${cartValue.toFixed(2)}</p>
          <p>Subtotal: ${cartValue.toFixed(2)}</p>
          <h3>Amount Due: ${cartValue.toFixed(2)}</h3>
        </div>
        <button type="submit" disabled={!stripe || loading}>
          {loading ? 'Processing...' : `Pay $${cartValue.toFixed(2)}`}
        </button>
        {errorMessage && <p className="error">{errorMessage}</p>}
        {successMessage && <p className="success">{successMessage}</p>}
      </form>
    </div>
  );
};

export default CheckoutForm;
